.poker-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.community-cards, .player-hand .cards {
  display: flex;
  gap: 5px;
}

.card {
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  img {
    width: 100%; /* Make the image take the full width of the div */
    height: auto; /* Maintain aspect ratio */
  }
}

.cards{
  display: flex;
}

.controls {
  position: absolute;
  bottom: 10px;
}

.controls-sub{
  display: flex;
  align-items: flex-end;
}

.controls button {
  margin: 5px;
}

.raise-slider{
  width: 250px;
  top: 10px;
}

.centerBoard {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.player-hand {
  background-color: white;
  padding: 10px;
  display: flex;
  gap: 10px;
}

.player-position-0 {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}

.player-position-1 {
  position: absolute;
  top: 50%;
  left: 10px;
  /* transform: translateX(-50%); */
}

.player-position-2 {
  position: absolute;
  top: 10px;
  left: 20%;
  transform: translateX(-50%);
}

.player-position-3 {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.player-position-4 {
  position: absolute;
  top: 10px;
  left: 80%;
  transform: translateX(-50%);
}

.player-position-5 {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateX(-50%);
}